import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SendIcon from "@mui/icons-material/SendRounded";
import RedoIcon from "@mui/icons-material/Redo";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Lottie from "react-lottie";
import {
  Box,
  Button,
  Checkbox,
  colors,
  FormControlLabel,
  Grid,
  MobileStepper,
  TextField,
} from "@mui/material";
import { FC, useCallback, useReducer, useRef, useState } from "react";
import axios from "axios";
import * as animationData from "../../assets/lotties/AnimatedCheck.json";
import { readString } from "react-papaparse";
import { isEqual } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";

function sleep(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

const labelTexts = {
  phone: "رقم الهاتف",
  state: "الولاية",
  city: "البلدية",
  name: "اسم المؤسسة",
  type: "نوع المؤسسة",
  email: "البريد الإلكتروني",
};

const errorTexts = {
  phone: "رقم الهاتف خاطئ",
  state: "اسم الولاية خاطئ",
  city: "اسم البلدية خاطئ",
  name: "اسم المؤسسة خاطئ",
  type: "نوع المؤسسة خاطئ",
  email: "عنوان البريد الإلكتروني خاطئ، ينبغي أن يكون عنوان Gmail",
};

const hintTexts = {
  phone: " ",
  state: " ",
  city: " ",
  name: " ",
  type: "ثانوية، متوسطة أي نوع آخر",
  email: "عنوان بريد الإكتروني Gmail يستعمل لإدارة التطبيق",
};

const tos = [
  "طلب تشغيل التطبيق من مسؤولية مدير المؤسسة أو أي شخص موكل من طرفه",
  "إدارة التطبيق تجرى عبر جدول بيانات Excel مستضاف على منصة Google Drive",
  "إدارة التطبيق تتطلب كمبيوتر، إنترنت و شخص ملم بأساسيات التعامل مع جداول البيانات Excel",
  "يمكن إدارة التطبيق أيضا إنطلاقا من واجهة مستخدم مرتبطة بجدول البيانات، لكن هذه الطريقة لها بعض الحدود",
  "يتعين على مستعلي الخدمة (مثلا التلاميذ) تنزيل التطبيق من متجر Google Play",
  "يتم تفعيل التطبيق عن طريق كود يجدونه في تطبيق مفعل مسبقا أو على مستوى المؤسسة",
  "مبرمجي التطبيق غير مسؤولين عن سوء استخدام التطبيق",
  "سلامة عمل التطبيق تعتمد على سلامة جداول البيانات، لذا يتعين على المسؤول عن الجداول حسن التسيير",
  "مبرمجو التطبيق مستعدون للمساعدة في حالة خلل في جدول البيانات في حدود الوقت المتاح لهم",
  "تستفيد المؤسسة من نسخة تجريبية لمدة شهر، ثم تدفع تكاليف الإشتراك للتفعيل النهائي للتطبيق",
  "مدة الإشتراك سنة كاملة، و تبدأ عند الدخول المدرسي في حالة طلب الإشتراك خلال العطلة الصيفية",
  "قرأت بعناية جميع الأحكام و الشروط السابقة و أوافق عليها",
];

const googleFormsUrl = "https://forms.gle/1x2xMHRRkKJVaEzc7";

const postFormUrl =
  "https://docs.google.com/forms/d/e/1FAIpQLSdsNYWasTzr3eocOTzf_70wcGSnEmg__KGUNNpnp7jo_7MCsw/formResponse";

const getDataUrl =
  "https://docs.google.com/spreadsheets/d/e/2PACX-1vSRruMdPlOpNd6tT3SQnRN6W3g12AXx32bZ_rr9t2C8QHR1rUvw0BD4D6knHanJ0ndpiKQF2ZMYG7vj/pub?gid=177554861&single=true&output=csv";

type Label = "phone" | "state" | "city" | "name" | "type" | "email";

const fields: Record<Label, string> = {
  email: "entry.197055289",
  phone: "entry.1983932687",
  state: "entry.1064639036",
  city: "entry.1489101955",
  name: "entry.875897812",
  type: "entry.2074562519",
};

const fieldValidations: Record<Label, RegExp> = {
  phone: /^0\d{9}$/,
  state: /^.{2,}$/,
  city: /^.{2,}$/,
  name: /^.{5,}$/,
  type: /^.{3,}$/,
  email: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+(@gmail.com)$/,
};

const labels = Object.keys(fields) as Label[];
type FieldName = keyof typeof fields;
type FieldState = { value: string; dirty: boolean };
type FormState = Record<FieldName, FieldState>;

const initialFormState: FormState = {
  phone: { value: "", dirty: false },
  state: { value: "", dirty: false },
  city: { value: "", dirty: false },
  name: { value: "", dirty: false },
  type: { value: "", dirty: false },
  email: { value: "", dirty: false },
};

const isFormValid = (formState: FormState): boolean =>
  labels
    .map((label) => fieldValidations[label].test(formState[label].value))
    .every((b) => b);

type ReducerAction = { reset?: true; key?: FieldName; state?: FieldState };

const formReducer = (state: FormState, action: ReducerAction) => {
  const { reset, key, state: fieldState } = action;
  if (reset) {
    return { ...initialFormState };
  } else if (key && fieldState) {
    return {
      ...state,
      [key]: fieldState,
    };
  } else {
    // should never be reached
    return { ...state };
  }
};

const assertDataHasBeenSubmited = async (
  data: FormState,
  retry = 0
): Promise<boolean> => {
  return new Promise((res) => {
    axios.get(`${getDataUrl}&_=${+new Date()}`).then((result) =>
      readString<string[]>(result.data, {
        complete: async (csvResult) => {
          const csvData = csvResult.data;
          const csvRows = csvData.map((row) => row.slice(1));
          const formRow = Object.values(data).map((datum) => datum.value);
          const exists = csvRows.some((row) =>
            isEqual(new Set(row), new Set([...formRow, ""]))
          );
          if (exists) {
            res(true);
          } else {
            if (retry > 5) {
              res(false);
            } else {
              await sleep(3000 * retry + 1);
              res(await assertDataHasBeenSubmited(data, retry + 1));
            }
          }
        },
      })
    );
  });
};

export const RegisterPage: FC = () => {
  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const textInputRefs = useRef<(HTMLInputElement | HTMLButtonElement | null)[]>(
    Array(labels.length + 1).fill(null)
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [tosAccepted, setTosAccepted] = useState<boolean[]>(
    Array(tos.length).fill(false)
  );
  const [formTOSChecked, setFormTOSChecked] = useState<boolean>(false);
  const [registered, setRegistered] = useState<boolean>(false);
  const [cantVerifySubmissionCount, setCantVerifySubmissionCount] =
    useState<number>(0);
  const [currentLabel, setCurrentLabel] = useState<Label | undefined>(
    undefined
  );

  const handleSubmit = useCallback(() => {
    setLoading(true);
    const data = Object.fromEntries(
      labels.map((label) => [fields[label], formState[label].value])
    );
    axios
      .postForm(postFormUrl, { ...data, fvv: 1, pageHistory: "0,1" })
      .catch(() => {
        /* HACK: nothing to do, we are hacking */
      })
      .finally(() => {
        assertDataHasBeenSubmited(formState)
          .then((submited) => {
            if (submited) {
              setRegistered(true);
              setLoading(false);
            } else {
              setCantVerifySubmissionCount((count) => count + 1);
            }
          })
          .finally(() => setLoading(false));
      });
  }, [formState]);

  const checkSubmission = useCallback(() => {
    setLoading(true);
    assertDataHasBeenSubmited(formState)
      .then((submited) => {
        if (submited) {
          setCantVerifySubmissionCount(0);
          setRegistered(true);
          setLoading(false);
        } else {
          setCantVerifySubmissionCount((count) => count + 1);
        }
      })
      .finally(() => setLoading(false));
  }, [formState]);

  const hasError = useCallback(
    (label: Label) =>
      formState[label].dirty &&
      !fieldValidations[label].test(formState[label].value),
    [formState]
  );

  return (
    <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{
          my: { xs: 3, md: 6 },
          p: { xs: 2, md: 3 },
          bgcolor:
            cantVerifySubmissionCount <= 3 &&
            !isEqual(tosAccepted.slice(-2), [true, false])
              ? colors.grey[50]
              : colors.red[50],
          overflow: "hidden",
        }}
      >
        {tosAccepted.some((b) => !b) ? (
          <Box
            sx={{
              height: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <MobileStepper
              variant="progress"
              steps={tos.length}
              position="static"
              activeStep={tosAccepted.filter((b) => b).length}
              sx={{
                transform: "translate(25%)",
                bgcolor: isEqual(tosAccepted.slice(-2), [true, false])
                  ? colors.red[50]
                  : colors.grey[50],
              }}
              nextButton={<></>}
              backButton={<></>}
            />
            <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
              {tos[tosAccepted.filter((b) => b).length]}
            </Typography>
            <Box textAlign="center">
              <Button
                onClick={() =>
                  setTosAccepted((prev) => {
                    const next = [...prev];
                    next[prev.filter((b) => b).length] = true;
                    return next;
                  })
                }
                color={
                  isEqual(tosAccepted.slice(-2), [true, false])
                    ? "error"
                    : "primary"
                }
                size="large"
              >
                موافق
              </Button>
            </Box>
          </Box>
        ) : cantVerifySubmissionCount > 0 ? (
          <>
            {cantVerifySubmissionCount <= 3 ? (
              <>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  sx={{ mb: 4 }}
                >
                  يبدو أن تسجيل الطلب يستغرق وقت أكثر من المعتاد
                </Typography>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <LoadingButton
                      variant="contained"
                      size="large"
                      endIcon={<RedoIcon style={{ transform: "scaleX(-1)" }} />}
                      onClick={checkSubmission}
                      loading={loading}
                    >
                      جرب مرة أخرى
                    </LoadingButton>
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  sx={{ mb: 4 }}
                >
                  التسجيل إنطلاقا من هذه الصفحة مستحيل في الوقت الحالي
                </Typography>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Button
                      variant="contained"
                      size="large"
                      endIcon={
                        <OpenInNewIcon style={{ transform: "scaleX(-1)" }} />
                      }
                      onClick={() => window.open(googleFormsUrl, "_blank")}
                    >
                      إستعمل صفحة أخرى
                    </Button>
                  </Box>
                </Grid>
              </>
            )}
          </>
        ) : registered ? (
          <>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              sx={{ mb: 4 }}
            >
              تم إرسال الطلب بنجاح
            </Typography>
            <Lottie options={{ animationData, loop: false }} />
            <Typography align="center">
              إذا كان لك أي إستفسار إتصل بنا عبر البريد الإلكتروني
            </Typography>
            <Typography align="center" sx={{ mb: 4 }}>
              <a
                href="mailto:contact@madrasati.app"
                className="no-text-decoration"
              >
                contact@madrasati.app
              </a>
            </Typography>
          </>
        ) : (
          <>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              sx={{ mb: 4 }}
            >
              طلب نسخة مجانية من تطبيق مدرستي
            </Typography>
            <Typography variant="h6" gutterBottom>
              يرجى ملء الإستمارة التالية بعناية من طرف مدير المؤسسة أو شخص مكلف
              من طرفه
            </Typography>
            <Grid container spacing={1}>
              {labels.map((label, index) => (
                <Grid
                  item
                  xs={12}
                  sm={["email", "phone"].includes(label) ? 12 : 6}
                  key={index}
                >
                  <TextField
                    ref={(el: HTMLInputElement) => {
                      textInputRefs.current[index] = el;
                    }}
                    error={hasError(label)}
                    helperText={
                      hasError(label)
                        ? errorTexts[label]
                        : label === currentLabel
                          ? hintTexts[label]
                          : " "
                    }
                    required
                    label={labelTexts[label]}
                    fullWidth
                    variant="standard"
                    onKeyDown={(key) => {
                      if (key.code === "Enter") {
                        const nextInput =
                          textInputRefs.current[
                            index + 1
                          ]?.getElementsByTagName("input")[0];
                        if (nextInput) {
                          nextInput.focus();
                        }
                      }
                    }}
                    onFocus={() => setCurrentLabel(label)}
                    onBlur={() =>
                      dispatch({
                        key: label,
                        state: {
                          value: formState[label].value.trim(),
                          dirty: true,
                        },
                      })
                    }
                    onChange={(event) =>
                      dispatch({
                        key: label,
                        state: { value: event.target.value, dirty: false },
                      })
                    }
                    value={formState[label].value}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{ pt: 3 }}
                  control={
                    <Checkbox
                      sx={{ alignSelf: "baseline" }}
                      color="secondary"
                      name="assert"
                      value="yes"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setFormTOSChecked(event.target.checked);
                      }}
                      ref={(el: HTMLButtonElement) => {
                        textInputRefs.current[labels.length] = el;
                      }}
                    />
                  }
                  label="أؤكد أنني مدير المؤسسة أو شخص مكلف من طرفه و أن المعلومات السابقة صحيحة"
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  variant="contained"
                  size="large"
                  sx={{ mt: 3 }}
                  disabled={!(isFormValid(formState) && formTOSChecked)}
                  loading={loading}
                  endIcon={<SendIcon style={{ transform: "scaleX(-1)" }} />}
                  onClick={handleSubmit}
                >
                  أرسل الطلب
                </LoadingButton>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Container>
  );
};
