import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { RegisterPage } from "./pages/RegisterPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import "./App.css";

export default function Register() {
  return (
    <>
      <CssBaseline />
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RegisterPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}
