import { AppBar, Toolbar } from "@mui/material";
import { FC } from "react";
import logo from "../../assets/images/logo.svg";

const Header: FC = () => {
  return (
    <AppBar
      position="absolute"
      color="default"
      elevation={0}
      sx={{
        position: "relative",
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <a href="https://madrasati.app">
          <img src={logo} alt="Madrassati.app" className="logo" />
        </a>
      </Toolbar>
    </AppBar>
  );
};

export { Header };
