import { colors, Container, Paper, Typography } from "@mui/material";
import { FC } from "react";

export const PrivacyPolicyPage: FC = () => {
  return (
    <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
      <Paper
        variant="outlined"
        sx={{
          my: { xs: 3, md: 6 },
          p: { xs: 2, md: 3 },
          bgcolor: colors.grey[50],
        }}
      >
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          سياسة الخصوصية لتطبيق مدرستي
        </Typography>
        <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
          تاريخ آخر تحديث: 3 مارس 2024
        </Typography>
        <section>
          <Typography variant="h4">المعلومات التي يتم جمعها</Typography>
          <Typography variant="body1">
            يتم جمع المعلومات عبر خدمة Google Analytics وتشمل البيانات التالية:
            <ul>
              <li>تفاعلات المستخدم مع التطبيق</li>
              <li>معلومات الجهاز المستخدم (مثل نوع الجهاز ونظام التشغيل)</li>
            </ul>
          </Typography>
        </section>
        <section>
          <Typography variant="h4">أغراض جمع البيانات</Typography>
          <Typography variant="body1">
            تتناسب جمع البيانات مع أغراض Google Analytics، والتي تشمل:
            <ul>
              <li>تحليل استخدام التطبيق</li>
              <li>تقديم تقارير حول أداء التطبيق</li>
              <li>تحسين تجربة المستخدم</li>
            </ul>
          </Typography>
        </section>
        <section>
          <Typography variant="h4">خدمات طرف ثالث</Typography>
          <Typography variant="body1">
            تعتمد التطبيق على خدمة Google Analytics كخدمة طرف ثالث لجمع وتحليل
            البيانات.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">موافقة المستخدم</Typography>
          <Typography variant="body1">
            يتم استخدام Google Analytics بموافقة المستخدم عند تثبيت التطبيق.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">تخزين البيانات وأمانها</Typography>
          <Typography variant="body1">
            تتم معالجة وتخزين البيانات بنفس الطريقة المستخدمة في Google
            Analytics. يرجى مراجعة سياسة الخصوصية الخاصة بـ Google Analytics
            للمزيد من التفاصيل حول تخزين البيانات وأمانها.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">مشاركة البيانات</Typography>
          <Typography variant="body1">
            تتم مشاركة المعلومات المجمعة فقط مع Google Analytics وفقًا للغرض
            المحدد لتحليل وتحسين الأداء.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">حقوق المستخدم</Typography>
          <Typography variant="body1">
            يتم منح المستخدمين نفس الحقوق التي يمنحها Google Analytics، بما في
            ذلك الحق في الوصول إلى البيانات وتصحيحها وحذفها.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">تحديثات سياسة الخصوصية</Typography>
          <Typography variant="body1">
            سيتم نشر أي تحديثات على سياسة الخصوصية على الرابط الذي يتم نشر هذا
            الإصدار عليه. سيتم إبلاغ المستخدمين بالتحديثات عبر البريد
            الإلكتروني.
          </Typography>
        </section>
        <section>
          <Typography variant="h4">معلومات الاتصال</Typography>
          <Typography variant="body1">
            لأية استفسارات أو مخاوف تتعلق بالخصوصية، يمكنك التواصل معنا عبر
            البريد الإلكتروني:{" "}
            <a href="mailto:contact@madrasati.app">contact@madrasati.app</a>.
          </Typography>
        </section>
      </Paper>
    </Container>
  );
};
